// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'cropperjs/dist/cropper.css';
.cropper-view-box {
	border-radius: 50%;
}
.cropper-face {
	background-color:inherit !important;
}
.cropper-wrap-box {
	background-color:inherit !important;
}

/** Fonts **/
/** Custom **/
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/40D10CBF2F464E0D8984C52CD9E5EF97.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/DE790A5F649E4734B97D3F10B0DC6A24.ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/DE790A5F649E4734B97D3F10B0DC6A24.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'RobotoSlab-Light';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/52C241C9B2D54CEB9F5A4930E3332101.ttf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'RobotoSlab-Bold';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/1193C511366A4C4A8DC121FBC0F9B38F.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'RobotoSlab-Regular';
  src: url('https://litello-tenancy-assets.s3.eu-central-1.amazonaws.com/fonts/B0622B62D43F4A6F8EA349395276BBCC.ttf');
  font-style: normal;
  font-weight: 400;
}

/** Litello **/
@font-face {
  font-family: 'Lora-Bold';
  src: url('./fonts/Lora-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lora-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Lora-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Lora-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Lora-Bold.svg#45db3c0e8b9e33b284d25695ef764ee4') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Lora-Italic';
  src: url('./fonts/Lora-Italic.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lora-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Lora-Italic.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Lora-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Lora-Italic.svg#33e0000900df319998cddaf127ac9fef') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Lora-Regular';
  src: url('./fonts/Lora-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Lora-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Lora-Regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Lora-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Lora-Regular.svg#bea24e1a5c40ba22b7c1317d297c1d3c') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SourceSansPro-Black';
  src: url('./fonts/SourceSansPro-Black.eot'); /* IE9 Compat Modes */
  src: url('./fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/SourceSansPro-Black.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/SourceSansPro-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/SourceSansPro-Black.svg#daf12afc31dc3d4b43e1f5e66ac75d3d') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url('./fonts/SourceSansPro-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/SourceSansPro-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/SourceSansPro-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/SourceSansPro-Bold.svg#f59ec01e9ce984a5e7320939532a55f3') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('./fonts/SourceSansPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/SourceSansPro-Regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/SourceSansPro-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/SourceSansPro-Regular.svg#acb2ff9f1528a62353501911e112e2e2') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}

/** Headers **/
h1 {
  font-family: var(--ion-font-family4);
  font-size: 28px;
  line-height: 32px;
}

h2 {
  font-family: var(--ion-font-family4);
  font-size: 24px;
  line-height: 28px;
}

h3 {
  font-family: var(--ion-font-family4);
  font-size: 21px;
  line-height: 24px;
}

h4 {
  font-size: 19px;
  line-height: 27px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

app-changelog {
  hr {
    height: 1px;
    border-width: 0;
    color: lightgray;
    background-color: lightgray;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  align-items: center;
}

.hover-container:hover {
  .hover-item {
    opacity: 1;
  }
}
.hover-item {
  opacity: 0;
  transition: opacity 0.2s;
}

.pointer {
  cursor: pointer;
}
.no-select {
  user-select: none;
}

.custom-spinner {
  img {
    width: 200px;
    height: 200px;
  }

  .loading-wrapper {
    background: transparent;
    box-shadow: none;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
    -webkit-padding-end: 0px;
    padding-inline-end: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #preloader {
    background-color: rgba(19, 0, 0, 0);
    // width:100vw;
    //  height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
  }

  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 60px;
    margin: -30px 0 0 -40px;
  }

  .preloader .lines {
    width: 80px;
    height: 40px;
    position: absolute;
  }

  .preloader .lines .line {
    width: 80px;
    height: 10px;
    background-color: var(--ion-color-primary);
    position: absolute;
    clip: rect(0, 0, 20px, 0);
  }

  .preloader .lines .line.line-1 {
    top: 0;
    animation: slide 2s ease 0s infinite;
  }

  .preloader .lines .line.line-2 {
    top: 15px;
    animation: slide 2s ease 0.25s infinite;
  }

  .preloader .lines .line.line-3 {
    top: 30px;
    animation: slide 2s ease 0.5s infinite;
  }

  @keyframes slide {
    0% {
      clip: rect(0, 0, 20px, 0);
    }
    30% {
      clip: rect(0, 80px, 20px, 0);
    }
    50% {
      clip: rect(0, 80px, 20px, 0);
    }
    80% {
      clip: rect(0, 80px, 20px, 80px);
    }
    100% {
      clip: rect(0, 80px, 20px, 80px);
    }
  }
}

.epub-container {
  margin: auto;
  max-width: 100vw;
  //height: 50vh;
}

.buttonCss {
  button.alert-button.close-button {
    color: black;
  }
}

.alert-message {
  overflow-y: auto;
}

body {
  position: absolute;
}

ion-app {
  .scroll-content {
    // prevent scrollbars from showing up in desktop browsers
    // in the side-menu and in the main content window
    overflow-y: auto;
  }
}

.image-modal-fullscreen .modal-wrapper {
  width: calc(100vw);
  height: calc(100vh);
  position: absolute;
  bottom: 0;
  left: 0;
}

.note-overview-modal {
  background-color: rgba(255, 255, 255, 0.8);
  --height: 17vh;
  --width: 80%;

  ion-backdrop {
    background: rgba(255, 255, 255, 0.5);
  }
}

.modal-create-note {
  --height: 20vh;
  --width: 90%;
}

ion-alert.sc-ion-alert-md-h {
  --background: white;
  --min-width: 450px !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  font-size: 14px;
}

.alert-button.sc-ion-alert-md{
  color: black !important;
}

ion-modal {
  &.confirm-dialog {
    ion-backdrop {
      background-color: transparent;
    }

    .modal-wrapper {
      --width: 500px;
      --height: calc(100% - 120px);
      margin-left: calc(100% - 620px);
      margin-top: 120px;
      margin-bottom: 24px;
      --box-shadow: none;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0;

      ion-content {
        .container {
          .buttons {
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  &.confirm-dialog-mobile {
    .modal-wrapper {
      --width: 95vw;
      --height: 170px;
      margin: auto;
      --box-shadow: none;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0;

      ion-content {
        .container {
          .buttons {
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  &.confirm-dialog-tablet {
    ion-backdrop {
      background-color: transparent;
    }

    .modal-wrapper {
      --width: 100%;
      --height: calc(100% - 120px);

      --box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 0;

      ion-content {
        .container {
          .buttons {
            justify-content: space-evenly;
          }
        }
      }
    }
  }

  &.ion-main-modal {
    --background: var(--ion-color-white);
    --width: calc(100% - 40vw + 115px);
    --max-width:900px;
    --height: calc(100% - 45vh + 105px);
  }
  &.ion-main-modal-large {
    --background: var(--ion-color-white);
    --width: calc(100% - 40vw + 115px);
    --max-width:900px;
    --height: calc(100% - 150px);
  }

  &.ion-main-modal-mobile {
    --background: var(--ion-color-white);
    --width: 100vw;
   // --height: calc(100% - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
    --height: 100%;
  }
}

.ion-modal-header {
  .header-toolbar {
    display: flex;
    align-items: center;
    --background: var(--ion-modal-header-color);
    height: calc(var(--ion-safe-area-top, 3rem) + 3rem);

    .header-title {
      color: var(--ion-modal-header-font-color);
      font-size: 1rem;
      padding-left: 1.5rem;
    }

    ion-buttons {
      ion-button {
        ion-icon {
          color: var(--ion-modal-header-font-color);
          font-size: 1.5rem;
        }
      }
    }
  }
}

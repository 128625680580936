// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #e6ff5a;
  --ion-color-primary-rgb: 230, 255, 90;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #cae04f;
  --ion-color-primary-tint: #e9ff6b;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* Footer*/
  --footer-background-color: #e6ff5a;
  --footer-font-color: '';

  /*Language selection*/
  --lang-selection-font-color: #504b48;

  /*General Colors*/
  --button-background-color: #504b48;
  --button-font-color: #504b48;

  /* Login */
  --login-background-color: '';
  --login-background-color1: #88e0b3;
  --login-background-color2: #f3fcc3;
  --login-form-background1: rgba(230, 255, 90, 0.25);
  --login-form-login-button: #ffffff;
  --login-form-login-button-text-color: #504b48;
  --login-form-registration-button: #504b48;
  --login-form-registration-button-text-color: #ffffff;
  --login-form-button-font: '';
  --login-text: '';
  --login-text-color: #504b48;
  --login-text-font: '';
  --login-logo: '';
  --login-input-icon-invalid-color: red;
  --login-input-icon-valid-color: #acc137;

  /* Registration */
  --registration-background-color1: #88e0b3;
  --registration-background-color2: #f3fcc3;
  --registration-page-background-image: #ffffff;
  --registration-form-background1: rgba(230, 255, 90, 0.25);
  --registration-form-registration-button: #ffffff;
  --registration-form-back-button: #504b48;
  --registration-form-back-button-text-color: #ffffff;
  --registration-form-registration-button-text-color: #504b48;
  --registration-text: '';
  --registration-text-color: #504b48;
  --registration-text-font: '';

  /* Forgot Password */
  --forgot-password-background-color1: #88e0b3;
  --forgot-password-background-color2: #f3fcc3;
  --forgot-password-page-background-image: #ffffff;
  --forgot-password-form-background1: rgba(230, 255, 90, 0.25);
  --forgot-password-form-back-button: #504b48;
  --forgot-password-form-back-button-text-color: #ffffff;
  --forgot-password-form-send-button: #ffffff;
  --forgot-password-form-send-button-text-color: #504b48;
  --forgot-password-text: '';
  --forgot-password-text-color: #504b48;
  --forgot-password-text-font: '';
  --forgot-password-button-font: '';

  /* Books dashboard (library) */
  --dashboard-background-color: #ffffff;

  --dashboard-top-picto-left: '';
  --dashboard-top-right-logo: '';
  --dashboard-top-text-right: '';

  --dashboard-header-background-image: '';
  --dashboard-header-background-color: #f7f9e8;
  --dashboard-header-logo: '';

  --dashboard-headline-text: '';
  --dashboard-headline-text-color: #504b48; //db
  --dashboard-headline-font: var(--ion-font-family5); //db

  --dashboard-intro-text: '';
  --dashboard-intro-text-color: #504b48;
  --dashboard-intro-font: var(--ion-font-family6);
  --dashboard-intro-backgroundcolor1: #88e0b3;
  --dashboard-intro-backgroundcolor2: #f3fcc3;
  --dashboard-intro-backgroundcolor3: #ffffff;

  --dashboard-intro-image: '';

  --dashboard-eBook-headline-text: '';
  --dashboard-eBook-headline-color: #00c6ff;
  --dashboard-eBook-headline-font: var(--ion-font-family5);

  --dashboard-teaser-backgroundcolor1: #f6f9e8; //db
  --dashboard-teaser-title-color: #acc137; //db
  --dashboard-teaser-author-color: #999998; //db
  --dashboard-teaser-text-color: #504b48; //db
  --dashboard-teaser-button-color: ''; //db
  --dashboard-teaser-font: var(--ion-font-family4);

  --dashboard-footer-icon: '';
  --dashboard-footer-text: '';
  --dashboard-footer-text-color: #000;
  --dashboar-footer-text-font: '';

  /* Book View */
  --book-header-title-color: #1e230f;
  --book-header-title-font: var(--ion-font-family1);
  --book-header-toc-back-button-hover: #dcdbda;
  --book-header-v-line: #dcdbda;
  --book-toc-button: #e6ff5a;
  --book-toc-button-text-color: #504b48;
  --book-toc-icon-color: #504b48;

  --book-attachment-item-hover: #e6ff5a;
  --book-attachment-item-selected: #e6ff5a;
  --book-attachment-boxShadow: rgba(187, 187, 187, 50%);
  --book-attachment-icon: #494a4a;
  --book-attachment-name: #504b48;
  --book-attachment-description: #504b48;
  --book-attachment-info: #767676;

  /* Book view mobile*/
  --book-header-dashboard-button-color: #e6ff5a;
  --book-header-toc-button-color: #e6ff5a;
  --book-header-toc-button-font-color: #504b48;

  --book-sidebar-color: #504b48;

  --book-sidebar-search-button-color: '';
  --book-sidebar-notes-button-color: '';
  --book-sidebar-attachment-button-color: '';
  --book-sidebar-search-button-active-color: '';
  --book-sidebar-notes-button-active-color: '';
  --book-sidebar-attachment-button-active-color: '';

  --book-sidebar-menu-header: #e6ff5a;
  --book-sidebar-menu-header-title-color: #504b48;

  --book-header-menu-burger: #504b48;
  --book-header-line: #d2d2d2;
  --book-header-toc-border: #e6ff5a;
  --book-header-menu-dashboard-icon: #e6ff5a;
  --book-header-menu-icon-color: #504b48;
  --book-header-menu-font-size-option: #acc137;

  --book-header-mobile-border: #acc137;

  --book-sidebar-menu-search-header-icon-color: #504b48;
  --book-sidebar-menu-search-button: #e6ff5a;
  --book-sidebar-menu-search-button-text-color: #e6ff5a;

  --book-sidebar-menu-note-header-icon-color: #504b48;

  --book-border-color: #979797;

  --ion-modal-header-color: #504b48;
  --ion-modal-header-font-color: #fff;

  /*Context Menu*/

  --book-sidebar-icon-inactive: white;
  --book-sidebar-icon-active: #e6ff5a;
  //"#d40511"
  --book-contextMenu-background: #504b48;
  --book-contextMenu-fontColor: #ffffff;
  --book-contextMenu-divider: #a6a59c;
  --book-contextMenu-color1: #e6ff5a;
  --book-contextMenu-color2: #00ff90;
  --book-contextMenu-color3: #00f6ff;
  --book-contextMenu-color4: #ff00ff;

  /*Profile view*/
  --profile-header-color: #504b48;
  --profile-text-color: rgba(80, 75, 71, 0.75);
  --profile-icon-color: rgba(80, 75, 71, 0.75);
  --profile-text-color-dark: rgba(80, 75, 71, 0.5);
  --profile-button-primary: #504b48;
  --profile-button-secondary: #b5b5b5;
  --profile-change-password-link: #7e7875;
  --profile-input-border: #7e7875;
  --profile-input-color: #7e7875;


  /* Contact Support*/
  --help-accent-color: #acc137;

  /*Note overview*/
  --search-border: #504b48;
  --search-toggle-button: #e6ff5a;

  /*Note overview*/
  --nov-border: #504b48;
  --nov-header-text: #504b48;
  --nov-info-text: #999998;
  --nov-content-text: #504b48;
  --nov-checkboc-selected: #acc137;
  --nov-chapter-name-content-title: #504b48;

  /*Create note*/
  --create-note-borders: #d8d8d8;
  --create-note-title-text: #504b48;
  --create-note-info-text: #999998;
  --create-note-content-text: #504b48;
  --create-note-description-text: #504b48;
  --create-note-cancel-button: #b5b5b5;
  --create-note-save-button: #504b48;

  /*Change password*/
  --profile-change-pass-text-color: #7e7875;
  --profile-change-pass-input-border: #7e7875;

  /*Changelog*/
  --changelog-line: #d8d8d8;

  /*Custom scrollbar*/
  --scrollbar-background-track: #d9d9d9;
  --scrollbar-background-thumb: #999998;

  /*Credits*/
  --credits-content-item-border: #d8d8d8;
  --credits-custom-icon-border: #979797;
  --credits-custom-icon-background: #d8d8d8;

  /*Data Privacy*/
  --dataprivacy-link: #4472c4;

  /*Dialog*/
  --dialog-background: rgba(255, 255, 255, 0.95);

  /* Highlight - Annotation colors */
  --highlight-color-1: rgba(230, 255, 90, 1);
  --highlight-color-2: rgba(0, 255, 144, 1);
  --highlight-color-3: rgba(0, 246, 255, 1);
  --highlight-color-4: rgba(255, 0, 255, 1);
  /* Highlight colors with 30% opacity cause epub.js convert highlight color automatically to 0.3 opacity */
  --highlight-color-1-opacity: rgba(230, 255, 90, 0.3);
  --highlight-color-2-opacity: rgba(0, 255, 144, 0.3);
  --highlight-color-3-opacity: rgba(0, 246, 255, 0.3);
  --highlight-color-4-opacity: rgba(255, 0, 255, 0.3);

  /* General colors */
  --ion-color-grey: var(--book-header-toc-button-font-color); // mostly used for text color
  --ion-color-medium-grey: rgba(0, 0, 0, 0.26); // used for box shadow and password reset label color
  --ion-color-light-grey: rgba(0, 0, 0, 0.1); // used for box shadow
  --ion-color-dark-grey: #737373; // used as text color
  --ion-color-deep-grey: #969391; // used as border color
  --ion-color-lawngreen: #7cfc00; // used on registration for valid icon
  --ion-color-white: #ffffff;
  --ion-color-black: #0a0a0a;
  --middle-grey: #969391;
  --darkt-grey: #504b48;

  //--ion-background-color: #fdfff2;
  --ion-background-color: #ffff;
  --ion-background-color-rgb: 253, 255, 242;

  .ion-color-grey {
    --ion-color-base: #0a0a0a;
    --ion-color-base-rgb: 80, 75, 72;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #46423f;
    --ion-color-tint: #625d5a;
  }

  .ion-color-white {
    --ion-color-base: #ffffff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #ffffff;
    --ion-color-light-tint: #ffffff;
  }

  /* Fonts */
  --ion-font-family: 'SourceSansPro-Bold';
  --ion-font-family1: 'Lora-Bold';
  --ion-font-family2: 'Lora-Italic';
  --ion-font-family3: 'Lora-Regular';
  --ion-font-family4: 'SourceSansPro-Black';
  --ion-font-family5: 'SourceSansPro-Bold';
  --ion-font-family6: 'SourceSansPro-Regular';

  /* Local Storage */
  --local-storage-item-border: #979797;

  --gradient-background: linear-gradient(191deg, #88e0b3 0%, #e8f9c1 55%, #f3fcc3 75%);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.global-network-modal {
  max-height: calc(var(--ion-safe-area-top) + 3.6rem);
  --background: rgba(255, 255, 255, 0.9);
}

.global-network-modal .modal-wrapper {
  width: 100%;
}
